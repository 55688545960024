.loading-overlay {
  position: absolute;
  z-index: 1;
  width: 3rem;
}

@media screen and (max-width: 749px) {
  .loading-overlay {
    top: 0;
    right: 0;
  }
}

@media screen and (min-width: 750px) {
  .loading-overlay {
    left: 0;
  }
}

.loading-overlay__spinner {
  width: 3rem;
  display: inline-block;
}

.spinner {
  animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: 280;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: rgb(var(--color-foreground));
  animation: dash 1.4s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 280;
  }
  50% {
    stroke-dashoffset: 75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 280;
    transform: rotate(450deg);
  }
}

.loading-overlay:not(.hidden) + .cart-item__price-wrapper,
.loading-overlay:not(.hidden) ~ cart-remove-button {
  opacity: 50%;
}

.loading-overlay:not(.hidden) ~ cart-remove-button {
  pointer-events: none;
  cursor: default;
}
